:root {
  --background-color: white;
  --text-color: #969696;
  --button-background-color: white;
  --button-text-color: #969696;
  --button-hover-background-color: #969696;
  --button-hover-text-color: #F0f0f0;
  --image-container-background-color: #F0f0f0;
  --tag-background-color: white;
  --tag-text-color: #969696;
  --input-background-color: #f0f0f0;
  --input-border-color: #969696;
  --popup-background-color: #4CAF50;
  --popup-text-color: white;
  --overlay-background-color: rgba(0, 0, 0, 0.8);
  --overlay-text-color: white;
}

[data-theme="dark"] {
  --background-color: #121212;
  --text-color: #e0e0e0;
  --button-background-color: #333;
  --button-text-color: #e0e0e0;
  --button-hover-background-color: #555;
  --button-hover-text-color: #e0e0e0;
  --image-container-background-color: #333;
  --tag-background-color: #333;
  --tag-text-color: #e0e0e0;
  --input-background-color: #333;
  --input-border-color: #555;
  --popup-background-color: #333;
  --popup-text-color: #e0e0e0;
  --overlay-background-color: rgba(0, 0, 0, 0.9);
  --overlay-text-color: #e0e0e0;
}

.App {
  text-align: center;
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: 5% 45% 7% 43%;
  height: 100vh;
  width: 100vw;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 24px;
}

h1 {
  grid-column: 2/2;
  grid-row: 1/1;
  font-weight: 400;
  font-size: 24px;
  text-align: left;
}

p {
  font-size: 18px;
  text-align: left;
  grid-column: 2/2;
  grid-row: 3/3;
  max-width: 90%;
  margin: 5%;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 2/2;
  grid-row: 2/2;
  width: 100%;
  height: 100%;
  padding: 8px;
  background-color: var(--image-container-background-color);
  border-radius: 12px;
  margin: 4px;
}

.inputContainer {
  grid-column: 2/2;
  grid-row: 4/4;
  margin-top: 20px;
  font-size: 18px;
}

.tags {
  display: flex;
  border-radius: 0px;
  width: 100%;
  margin-bottom: 5%;
}

.tags span {
  font-size: 18px;
  padding: 6px;
  background-color: var(--tag-background-color);
  border-radius: 6px;
  margin-right: 8px;
  color: var(--tag-text-color);
}

.uploaded-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

input[type="file"] {
  display: none;
}

.upload-button {
  padding: 10px 20px;
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.upload-button:hover {
  background-color: var(--button-hover-background-color);
  color: var(--button-hover-text-color);
}

#tags .ReactTags__selected {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: calc(6 * 24px); /* Adjust 24px to the height of each tag plus margin/padding */
}

#tags span.ReactTags__tag {
  font-size: 18px;
  padding: 6px;
  background-color: var(--input-background-color);
  border-radius: 6px;
  margin-right: 8px;
  color: var(--tag-text-color);
  width: fit-content;
  margin-bottom: 8px; /* To give space between rows */
}

#tags .ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: var(--tag-text-color);
  margin-left: 5px;
}

#tags svg {
  fill: #997979;
}

#tags .ReactTags__tagInputField {
  font-size: 18px;
  padding: 6px;
  background-color: var(--input-background-color);
  border-radius: 6px;
  border: 1px solid var(--input-border-color);
  margin: 10px;
  color: var(--tag-text-color);
  width: 50%;
}

.submit-button {
  padding: 10px 20px;
  background-color: var(--input-background-color);
  color: var(--tag-text-color);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin: 5px;
}

.submit-button:hover {
  background-color: var(--button-hover-background-color);
  color: var(--button-hover-text-color);
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--popup-background-color);
  color: var(--popup-text-color);
  padding: 20px;
  border-radius: 5px;
  animation: popup-fade-in 0.5s ease forwards;
}

.popup-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes popup-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Adjust as needed */
}

.overlay-content {
  text-align: center;
  color: var(--overlay-text-color);
}

.overlay-content span {
  font-size: 4rem; /* Adjust size of thumbs-up emoji */
}

.overlay-content h1 {
  margin-top: 20px;
}